import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { MatIconRegistry } from '@angular/material/icon';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { SharedModule } from './shared/shared.module';
import { Configuration, ConfigurationParameters, ApiModule } from './swagger';
import { environment } from 'src/environments/environment';
import { RequestInterceptor } from './request.interceptor';
import { ChartsModule } from './views/charts/charts.module';
import { SessionService } from './services/session.service';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SimplebarAngularModule } from 'simplebar-angular';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { QuillModule } from 'ngx-quill';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LoadComponent } from './standalone/load/load.component';
import { SignoutComponent } from './standalone/signout/signout.component';
import { PreJoinComponent } from './standalone/pre-join/pre-join.component';
import { LoadDialogComponent } from './standalone/load-dialog/load-dialog.component';
import { TourMatMenuModule, TourService } from 'ngx-ui-tour-md-menu';
import { NgxEditorModule } from 'ngx-editor';

export function apiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    basePath: environment.baseApiUrl,
    apiKeys: { 'Authorization': null }
  };
  return new Configuration(params);
}

export function tokenConfig() {
  return localStorage.getItem('token');
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    LoadComponent,
    SignoutComponent,
    LoadDialogComponent,
    PreJoinComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ApiModule.forRoot(apiConfigFactory),
    JwtModule.forRoot({ config: { tokenGetter: tokenConfig } }),
    MaterialModule,
    FormsModule,
    ImageCropperModule,
    SharedModule,
    NgbModule,
    HttpClientModule,
    NgProgressModule,
    NgProgressHttpModule,
    ChartsModule,
    ClipboardModule,
    QuillModule.forRoot(),
    SimplebarAngularModule,
    RecaptchaV3Module,
    NgxEditorModule.forRoot(),
    TourMatMenuModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    TourService,
    SessionService,
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeeiMAZAAAAALinfToGaPRngg4ArPE3clxdAiPQ' },
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500, horizontalPosition: 'center' } }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(iconRegistry: MatIconRegistry) {
    iconRegistry.setDefaultFontSetClass('material-symbols-rounded');
  }
}
