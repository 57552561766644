/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CouponsLinkedResultView } from '../model/couponsLinkedResultView';
import { Enabled } from '../model/enabled';
import { ShopifyAdminAuthRequestView } from '../model/shopifyAdminAuthRequestView';
import { ShopifyApiType } from '../model/shopifyApiType';
import { ShopifyApperanceView } from '../model/shopifyApperanceView';
import { ShopifyChangePlanView } from '../model/shopifyChangePlanView';
import { ShopifyCollectionUpdateConfigView } from '../model/shopifyCollectionUpdateConfigView';
import { ShopifyCollectionsResultView } from '../model/shopifyCollectionsResultView';
import { ShopifyCreateCouponView } from '../model/shopifyCreateCouponView';
import { ShopifyCreateGiftCardCouponView } from '../model/shopifyCreateGiftCardCouponView';
import { ShopifyCustomerView } from '../model/shopifyCustomerView';
import { ShopifyGdprWebhookType } from '../model/shopifyGdprWebhookType';
import { ShopifyLevelDiscountView } from '../model/shopifyLevelDiscountView';
import { ShopifyMetaFieldResultView } from '../model/shopifyMetaFieldResultView';
import { ShopifyMetafieldInsertView } from '../model/shopifyMetafieldInsertView';
import { ShopifyOnOrderArnView } from '../model/shopifyOnOrderArnView';
import { ShopifyOrderWebhookType } from '../model/shopifyOrderWebhookType';
import { ShopifyOrderWebhookView } from '../model/shopifyOrderWebhookView';
import { ShopifyProductWebHookType } from '../model/shopifyProductWebHookType';
import { ShopifyRedeemPointsView } from '../model/shopifyRedeemPointsView';
import { ShopifyRefundView } from '../model/shopifyRefundView';
import { ShopifyShopSchemeIntegrationLink } from '../model/shopifyShopSchemeIntegrationLink';
import { ShopifySynchronizationProgressView } from '../model/shopifySynchronizationProgressView';
import { ShopifyThemeModifyView } from '../model/shopifyThemeModifyView';
import { ShopifyVendorView } from '../model/shopifyVendorView';
import { Webhook } from '../model/webhook';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ShopifyService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Obtains access token on the basis of AuthUrl
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyAccessTokenPost(body?: ShopifyShopSchemeIntegrationLink, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyAccessTokenPost(body?: ShopifyShopSchemeIntegrationLink, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyAccessTokenPost(body?: ShopifyShopSchemeIntegrationLink, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyAccessTokenPost(body?: ShopifyShopSchemeIntegrationLink, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/AccessToken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyApperanceSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyApperanceSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/Apperance/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyApperanceSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyApperanceSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/Apperance/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyApperanceSchemeIntegrationIdPut(schemeIntegrationId: string, body?: ShopifyApperanceView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyApperanceSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Shopify/Apperance/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyAuthPost(body?: ShopifyAdminAuthRequestView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyAuthPost(body?: ShopifyAdminAuthRequestView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyAuthPost(body?: ShopifyAdminAuthRequestView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyAuthPost(body?: ShopifyAdminAuthRequestView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/Auth`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param planId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyChangePlanGet(planId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyChangePlanGet(planId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyChangePlanGet(planId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyChangePlanGet(planId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (planId !== undefined && planId !== null) {
            queryParameters = queryParameters.set('planId', <any>planId);
        }
        if (scheme !== undefined && scheme !== null) {
            queryParameters = queryParameters.set('scheme', <any>scheme);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/ChangePlan`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Changes recurring charge to that of new plan
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyChangeSubscriptionPost(body?: ShopifyChangePlanView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyChangeSubscriptionPost(body?: ShopifyChangePlanView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyChangeSubscriptionPost(body?: ShopifyChangePlanView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyChangeSubscriptionPost(body?: ShopifyChangePlanView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/ChangeSubscription`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Finishes shopify OAuth install 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyCompleteInstallationSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyCompleteInstallationSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyCompleteInstallationSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyCompleteInstallationSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyCompleteInstallationSchemeIntegrationIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/CompleteInstallation/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get config for shopify
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyConfigSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyConfigSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/Config/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes config by id
     * 
     * @param schemeIntegrationId 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyConfigSchemeIntegrationIdIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyConfigSchemeIntegrationIdIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyConfigSchemeIntegrationIdIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyConfigSchemeIntegrationIdIdDelete(schemeIntegrationId: string, id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyConfigSchemeIntegrationIdIdDelete.');
        }

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiShopifyConfigSchemeIntegrationIdIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/api/Shopify/Config/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates or updates config for shopify
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyConfigSchemeIntegrationIdPut(schemeIntegrationId: string, body?: { [key: string]: string; }, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyConfigSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Shopify/Config/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Configure webHooks for RIG
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Webhook>;
    public apiShopifyConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Webhook>>;
    public apiShopifyConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Webhook>>;
    public apiShopifyConfigureRigWebHooksSchemeIntegrationIdPost(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyConfigureRigWebHooksSchemeIntegrationIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Webhook>('post',`${this.basePath}/api/Shopify/ConfigureRigWebHooks/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Configure webHooks for specified scheme
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyConfigureWebHooksForSchemeSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Webhook>>;
    public apiShopifyConfigureWebHooksForSchemeSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Webhook>>>;
    public apiShopifyConfigureWebHooksForSchemeSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Webhook>>>;
    public apiShopifyConfigureWebHooksForSchemeSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyConfigureWebHooksForSchemeSchemeIntegrationIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyConfigureWebHooksForSchemeSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Webhook>>('post',`${this.basePath}/api/Shopify/ConfigureWebHooksForScheme/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new coupon for shopify with percentage
     * 
     * @param schemeIntegrationId 
     * @param profileId 
     * @param body 
     * @param scheme 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyCouponSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateCouponView, scheme?: string, xApiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiShopifyCouponSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateCouponView, scheme?: string, xApiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiShopifyCouponSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateCouponView, scheme?: string, xApiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiShopifyCouponSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateCouponView, scheme?: string, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyCouponSchemeIntegrationIdProfileIdPost.');
        }

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiShopifyCouponSchemeIntegrationIdProfileIdPost.');
        }




        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/Shopify/Coupon/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(profileId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Checks if the integration is enabled
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyEnabledSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyEnabledSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets Integration enabled status
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyEnabledSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Enabled, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyEnabledSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/Enabled/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new coupon for shopify
     * 
     * @param schemeIntegrationId 
     * @param profileId 
     * @param body 
     * @param scheme 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyExchangePointsSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyRedeemPointsView, scheme?: string, xApiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiShopifyExchangePointsSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyRedeemPointsView, scheme?: string, xApiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiShopifyExchangePointsSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyRedeemPointsView, scheme?: string, xApiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiShopifyExchangePointsSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyRedeemPointsView, scheme?: string, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyExchangePointsSchemeIntegrationIdProfileIdPost.');
        }

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiShopifyExchangePointsSchemeIntegrationIdProfileIdPost.');
        }




        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/Shopify/ExchangePoints/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(profileId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of shopify collections
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ShopifyCollectionsResultView>>;
    public apiShopifyGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ShopifyCollectionsResultView>>>;
    public apiShopifyGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ShopifyCollectionsResultView>>>;
    public apiShopifyGetShopifyCollectionsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyGetShopifyCollectionsSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ShopifyCollectionsResultView>>('get',`${this.basePath}/api/Shopify/GetShopifyCollections/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyGetSyncTransactionsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyGetSyncTransactionsProgressSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/GetSyncTransactionsProgress/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param type 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyGetWebhooksSchemeIntegrationIdTypeGet(schemeIntegrationId: string, type: ShopifyApiType, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyGetWebhooksSchemeIntegrationIdTypeGet.');
        }

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyGetWebhooksSchemeIntegrationIdTypeGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/GetWebhooks/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(type))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a new coupon for shopify
     * 
     * @param schemeIntegrationId 
     * @param profileId 
     * @param body 
     * @param scheme 
     * @param xApiKey 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyGiftCardSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateGiftCardCouponView, scheme?: string, xApiKey?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiShopifyGiftCardSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateGiftCardCouponView, scheme?: string, xApiKey?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiShopifyGiftCardSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateGiftCardCouponView, scheme?: string, xApiKey?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiShopifyGiftCardSchemeIntegrationIdProfileIdPost(schemeIntegrationId: string, profileId: string, body?: ShopifyCreateGiftCardCouponView, scheme?: string, xApiKey?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyGiftCardSchemeIntegrationIdProfileIdPost.');
        }

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiShopifyGiftCardSchemeIntegrationIdProfileIdPost.');
        }




        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/Shopify/GiftCard/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(profileId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param schemeIntegrationId 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyLevelDiscountSchemeIntegrationIdGet(schemeIntegrationId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyLevelDiscountSchemeIntegrationIdGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/LevelDiscount/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyLevelDiscountSchemeIntegrationIdPost(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyLevelDiscountSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/LevelDiscount/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyLevelDiscountSchemeIntegrationIdPut(schemeIntegrationId: string, body?: Array<ShopifyLevelDiscountView>, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyLevelDiscountSchemeIntegrationIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Shopify/LevelDiscount/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates auth url for an app
     * 
     * @param shop 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyLoyaleSetupGet(shop?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyLoyaleSetupGet(shop?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyLoyaleSetupGet(shop?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyLoyaleSetupGet(shop?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (shop !== undefined && shop !== null) {
            queryParameters = queryParameters.set('shop', <any>shop);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/LoyaleSetup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyLoyaleShopifyCollectionSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyLoyaleShopifyCollectionSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyLoyaleShopifyCollectionSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyLoyaleShopifyCollectionSchemeIntegrationIdGet(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyLoyaleShopifyCollectionSchemeIntegrationIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/LoyaleShopifyCollection/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates meta field
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<ShopifyMetaFieldResultView>;
    public apiShopifyMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShopifyMetaFieldResultView>>;
    public apiShopifyMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShopifyMetaFieldResultView>>;
    public apiShopifyMetaFieldSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyMetafieldInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyMetaFieldSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ShopifyMetaFieldResultView>('post',`${this.basePath}/api/Shopify/MetaField/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnGdprTypePost(type: ShopifyGdprWebhookType, body?: { [key: string]: any; }, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyOnGdprTypePost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnGdpr/${encodeURIComponent(String(type))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnOrderRigAwsSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOnOrderArnView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnOrderRigAwsSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOnOrderArnView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnOrderRigAwsSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOnOrderArnView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnOrderRigAwsSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOnOrderArnView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyOnOrderRigAwsSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnOrderRigAwsSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnOrderRigAwsSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnOrderRigAws/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnOrderRigTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyOnOrderRigTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnOrderRigTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnOrderRigTypeSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnOrderRig/${encodeURIComponent(String(type))}/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnOrderTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyOrderWebhookType, schemeIntegrationId: string, schemeId: string, body?: ShopifyOrderWebhookView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyOnOrderTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnOrderTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnOrderTypeSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnOrder/${encodeURIComponent(String(type))}/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Webhook for customer register event
     * 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyCustomerView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyCustomerView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyCustomerView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyCustomerView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnShopifyCustomerCreateSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnShopifyCustomerCreate/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param type 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyProductWebHookType, schemeIntegrationId: string, schemeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyProductWebHookType, schemeIntegrationId: string, schemeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyProductWebHookType, schemeIntegrationId: string, schemeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost(type: ShopifyProductWebHookType, schemeIntegrationId: string, schemeId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (type === null || type === undefined) {
            throw new Error('Required parameter type was null or undefined when calling apiShopifyOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnShopifyProductTypeSchemeIntegrationIdSchemeIdPost.');
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnShopifyProduct/${encodeURIComponent(String(type))}/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnShopifyRefundRigAwsSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnShopifyRefundRigAwsSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnShopifyRefundRigAwsSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnShopifyRefundRigAwsSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnShopifyRefundRigAwsSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnShopifyRefundRigAwsSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnShopifyRefundRigAws/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnShopifyRefundRigSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnShopifyRefundRig/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param schemeId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyOnShopifyRefundSchemeIntegrationIdSchemeIdPost(schemeIntegrationId: string, schemeId: string, body?: ShopifyRefundView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyOnShopifyRefundSchemeIntegrationIdSchemeIdPost.');
        }

        if (schemeId === null || schemeId === undefined) {
            throw new Error('Required parameter schemeId was null or undefined when calling apiShopifyOnShopifyRefundSchemeIntegrationIdSchemeIdPost.');
        }


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/OnShopifyRefund/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(schemeId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Removes webhooks for scheme
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Webhook>>;
    public apiShopifyRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Webhook>>>;
    public apiShopifyRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Webhook>>>;
    public apiShopifyRemoveWebHooksSchemeIntegrationIdDelete(schemeIntegrationId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyRemoveWebHooksSchemeIntegrationIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Webhook>>('delete',`${this.basePath}/api/Shopify/RemoveWebHooks/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Generates auth url for an app
     * 
     * @param hmac 
     * @param host 
     * @param shop 
     * @param timestamp 
     * @param session 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifySetupGet(hmac?: string, host?: string, shop?: string, timestamp?: string, session?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifySetupGet(hmac?: string, host?: string, shop?: string, timestamp?: string, session?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifySetupGet(hmac?: string, host?: string, shop?: string, timestamp?: string, session?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifySetupGet(hmac?: string, host?: string, shop?: string, timestamp?: string, session?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (hmac !== undefined && hmac !== null) {
            queryParameters = queryParameters.set('hmac', <any>hmac);
        }
        if (host !== undefined && host !== null) {
            queryParameters = queryParameters.set('host', <any>host);
        }
        if (shop !== undefined && shop !== null) {
            queryParameters = queryParameters.set('shop', <any>shop);
        }
        if (timestamp !== undefined && timestamp !== null) {
            queryParameters = queryParameters.set('timestamp', <any>timestamp);
        }
        if (session !== undefined && session !== null) {
            queryParameters = queryParameters.set('session', <any>session);
        }

        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/Setup`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Shopify recurring charge information for scheme
     * 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyShopifySubscriptionGet(scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyShopifySubscriptionGet(scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyShopifySubscriptionGet(scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyShopifySubscriptionGet(scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/ShopifySubscription`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates a Loyale Recurring Charge on Shopify - Mainly to be used for testing Purposes or in case of errors while setup
     * 
     * @param trial 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyShopifySubscriptionTrialPost(trial: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyShopifySubscriptionTrialPost(trial: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyShopifySubscriptionTrialPost(trial: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyShopifySubscriptionTrialPost(trial: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (trial === null || trial === undefined) {
            throw new Error('Required parameter trial was null or undefined when calling apiShopifyShopifySubscriptionTrialPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/ShopifySubscription/${encodeURIComponent(String(trial))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifySyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<ShopifySynchronizationProgressView>;
    public apiShopifySyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShopifySynchronizationProgressView>>;
    public apiShopifySyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShopifySynchronizationProgressView>>;
    public apiShopifySyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifySyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifySyncLoyaleCollectionProductsProgressSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShopifySynchronizationProgressView>('get',`${this.basePath}/api/Shopify/SyncLoyaleCollectionProductsProgress/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifySyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifySyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifySyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifySyncLoyaleCollectionProductsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifySyncLoyaleCollectionProductsSchemeIntegrationIdPost.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifySyncLoyaleCollectionProductsSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/SyncLoyaleCollectionProducts/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param dateFrom 
     * @param dateTo 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifySyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifySyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifySyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifySyncTransactionsSchemeIntegrationIdDateFromDateToPost(schemeIntegrationId: string, dateFrom: Date, dateTo: Date, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifySyncTransactionsSchemeIntegrationIdDateFromDateToPost.');
        }

        if (dateFrom === null || dateFrom === undefined) {
            throw new Error('Required parameter dateFrom was null or undefined when calling apiShopifySyncTransactionsSchemeIntegrationIdDateFromDateToPost.');
        }

        if (dateTo === null || dateTo === undefined) {
            throw new Error('Required parameter dateTo was null or undefined when calling apiShopifySyncTransactionsSchemeIntegrationIdDateFromDateToPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/SyncTransactions/${encodeURIComponent(String(schemeIntegrationId))}/${encodeURIComponent(String(dateFrom))}/${encodeURIComponent(String(dateTo))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifySyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<ShopifySynchronizationProgressView>;
    public apiShopifySyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ShopifySynchronizationProgressView>>;
    public apiShopifySyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ShopifySynchronizationProgressView>>;
    public apiShopifySyncVendorsProgressSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifySyncVendorsProgressSchemeIntegrationIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifySyncVendorsProgressSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ShopifySynchronizationProgressView>('get',`${this.basePath}/api/Shopify/SyncVendorsProgress/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets synchronization progress
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifySyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifySyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifySyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifySyncVendorsSchemeIntegrationIdPost(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifySyncVendorsSchemeIntegrationIdPost.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifySyncVendorsSchemeIntegrationIdPost.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/SyncVendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sets up assets for current theme
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyThemeSetUpSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyThemeModifyView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyThemeSetUpSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/ThemeSetUp/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Tries to use existing coupon
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiShopifyTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiShopifyTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiShopifyTryUseCouponSchemeIntegrationIdPost(schemeIntegrationId: string, body?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyTryUseCouponSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/Shopify/TryUseCoupon/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param schemeIntegrationId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyUpdateLoyaleShopifyCollectionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCollectionUpdateConfigView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyUpdateLoyaleShopifyCollectionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCollectionUpdateConfigView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyUpdateLoyaleShopifyCollectionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCollectionUpdateConfigView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyUpdateLoyaleShopifyCollectionsSchemeIntegrationIdPost(schemeIntegrationId: string, body?: ShopifyCollectionUpdateConfigView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyUpdateLoyaleShopifyCollectionsSchemeIntegrationIdPost.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/UpdateLoyaleShopifyCollections/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets list of vendors
     * 
     * @param schemeIntegrationId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyVendorsSchemeIntegrationIdGet(schemeIntegrationId: string, scheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyVendorsSchemeIntegrationIdGet.');
        }

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyVendorsSchemeIntegrationIdGet.');
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Shopify/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates new binding of outletId with franchise name
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyVendorsSchemeIntegrationIdPost(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyVendorsSchemeIntegrationIdPost.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyVendorsSchemeIntegrationIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Shopify/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Updates binding of outletId with franchise name
     * 
     * @param scheme 
     * @param schemeIntegrationId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiShopifyVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiShopifyVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiShopifyVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiShopifyVendorsSchemeIntegrationIdPut(scheme: string, schemeIntegrationId: string, body?: Array<ShopifyVendorView>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiShopifyVendorsSchemeIntegrationIdPut.');
        }

        if (schemeIntegrationId === null || schemeIntegrationId === undefined) {
            throw new Error('Required parameter schemeIntegrationId was null or undefined when calling apiShopifyVendorsSchemeIntegrationIdPut.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('put',`${this.basePath}/api/Shopify/Vendors/${encodeURIComponent(String(schemeIntegrationId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
