import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { SessionService } from './services/session.service';

@Injectable()
export class RequestInterceptor {
  constructor(private sessionService: SessionService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (JSON.parse(localStorage.getItem('joinFlow'))) {
      //request = request.clone();

      if (localStorage.getItem('joinSchemeId')) {
        request = request.clone({
          setHeaders: {
            'X-Scheme': localStorage.getItem('joinSchemeId')
          }
        });
      }

      if (localStorage.getItem('joinToken')) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${localStorage.getItem('joinToken')}`
          }
        });
      }

      // if (localStorage.getItem('joinSchemeId')) {
      //   request = request.clone({
      //     setHeaders: {
      //       Authorization: `Bearer ${localStorage.getItem('joinToken')}`,
      //       'X-Scheme': localStorage.getItem('joinSchemeId')
      //     }
      //   });
      // } else {
      //   request = request.clone({
      //     setHeaders: {
      //       Authorization: `Bearer ${localStorage.getItem('joinToken')}`
      //     }
      //   });
      // }
    } else {
      if (this.sessionService.schemeId) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.sessionService.token}`,
            'X-Scheme': this.sessionService.schemeId,
            'X-Frame-Options': 'DENY'
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.sessionService.token}`,
            'X-Frame-Options': 'DENY'
          }
        });
      }
    }

    return next.handle(request);
  }
}
