import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'loyale-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  @Input() category = null;
  @Input() item = null;
  @Input() message = null;
  @Input() disableTour = true;
  @Input() hideTour = true;
  @Input() icon = null;
  isExpanded = true;

  @Output() startTourEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {

  }

  startTourTrigger() {
    this.startTourEvent.emit(true);
  }
}
