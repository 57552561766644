import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[contains]',
  providers: [{ provide: NG_VALIDATORS, useExisting: ContainsDirective, multi: true }]
})
export class ContainsDirective implements Validator {
  @Input('contains') contains;

  constructor() {
  }

  validate(c: AbstractControl) {
    let isValid = this.includes(c.value, '{}');

    const validationState = !isValid ? { contains: true } : null;
    return validationState;
  }

  includes(string, blockedChars) {
    if (string != null || string != undefined) {
      for (let i = 0; i < string.length; i++) {
        var char = string.charAt(i);
        if (blockedChars.indexOf(char) >= 0) {
          return false;
        }
      }

      return true;
    }
  }

}
