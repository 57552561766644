export enum UrlAccordanceEnum {
  GeneralConformity = 'general',
  CustomersConformity = 'customers',
  ReachConformity = 'reach',
  RewardsConformity = 'rewards',
  AutomationsConformity = 'automations',
  AddonsConformity = 'addons',
  SettingConformity = 'settings',
  UserConformity = 'user'
}

export enum GeneralConformity {
  'dashboard',
  'transactions',
  'reports',
  'franchises',
  'outlets',
  'product'
}

export enum CustomersConformity {
  'customers',
  'tiers',
  'demographics',
  'tags',
  'messages',
  'levels'
}

export enum ReachConformity {
  'push-notifications',
  'emails',
  'alerts',
  'feedbacks',
  'sms'
}

export enum RewardsConformity {
  'coupons',
  'gift-cards',
  'referrals',
  'promo-codes',
  'welcome',
  'lotteries',
  'collections',
  'tickets',
  'prizes',
  'points'
}

export enum AutomationsConformity {
  'automations',
}

export enum AddonsConformity {
  'integrations',
  'webhooks'

}

export enum SettingsConformity {
  'settings'
}

export enum UserConformity {
  'user',

}

export const getSectionNameByUrl = (url: string): string => {
  if (GeneralConformity[url] !== undefined) {
    return 'general';
  }

  if (CustomersConformity[url] !== undefined) {
    return 'customers';
  }

  if (ReachConformity[url] !== undefined) {
    return 'reach';
  }

  if (RewardsConformity[url] !== undefined) {
    return 'rewards';
  }

  if (AutomationsConformity[url] !== undefined) {
    return 'automations';
  }

  if (AddonsConformity[url] !== undefined) {
    return 'addons';
  }

  if (SettingsConformity[url] !== undefined) {
    return 'settings';
  }

  if (UserConformity[url] !== undefined) {
    return 'user';
  }

  return 'general';
};

