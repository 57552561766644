/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Coordinate } from './coordinate';
import { CouponOutlet } from './couponOutlet';
import { Franchise } from './franchise';
import { LocationType } from './locationType';
import { Transaction } from './transaction';
import { WebStoreType } from './webStoreType';

export interface Outlet { 
    id?: string;
    franchiseId?: string;
    franchise?: Franchise;
    name?: string;
    coordinate?: Coordinate;
    address?: Address;
    phoneNumber?: string;
    openingHours?: Array<string>;
    imageUrl?: string;
    facebook?: string;
    instagram?: string;
    hidden?: boolean;
    transactions?: Array<Transaction>;
    couponOutlets?: Array<CouponOutlet>;
    type?: LocationType;
    webstoreUrl?: string;
    contactEmail?: string;
    createdBy?: string;
    updatedBy?: string;
    isDefault?: boolean;
    webstoreType?: WebStoreType;
    createdDate?: Date;
    updatedDate?: Date;
}