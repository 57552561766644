<div class="intro mb-4">
  <div class="py-5 px-4" tourAnchor="introTourStartButton">
    <div class="d-flex justify-content-between align-items-start w-100">
      <!-- <div class="left-wrapper">
        <div class="category-wrapper d-flex align-items-center" [ngClass]="{'mb-1': item}">
          <mat-icon color="accent" class="icon" [ngClass]="{'mr-1': !item, 'd-none': item}">{{icon}}</mat-icon>
          <div class="text-capitalize" [ngClass]="{'h3': !item, 'small': item}">{{category}}</div>
        </div>
        <div class="item-wrapper" *ngIf="item">
          <div class="item text-capitalize h3">{{item}}</div>
        </div>
        <div class="message-wrapper" *ngIf="message">
          <p class="text-grey m-0">{{message}}</p>
        </div>
      </div> -->

      <div class="left-wrapper">
        <div class="category-wrapper d-flex align-items-center">
          <mat-icon color="accent" class="icon">{{icon}}</mat-icon>
          <h3 class="text-capitalize ml-2 mb-0">{{category}}</h3>
          <mat-chip-list class="pointer-events-none ml-2" *ngIf="item">
            <mat-chip class="pointer-events-none">
              <span class="text-capitalize">{{item}}</span>
            </mat-chip>
          </mat-chip-list>
        </div>
        <div class="message-wrapper" *ngIf="message">
          <p class="text-grey m-0">{{message}}</p>
        </div>
      </div>

      <div class="right-wrapper" *ngIf="!hideTour">
        <button mat-icon-button (click)="startTourTrigger()" matTooltip="Tour" [disabled]="disableTour">
          <mat-icon>tour</mat-icon>
        </button>
      </div>
    </div>
  </div>
  <ng-content>
  </ng-content>
</div>
