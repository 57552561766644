import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComplexDirective } from './complex.directive';
import { PostcodeDirective } from './postcode.directive';
import { WholeDirective } from './whole.directive';
import { ContainsDirective } from './contains.directive';
import { VatnumberDirective } from './vatnumber.directive';
import { DynamicFieldSharedDirective } from './dynamic-field.directive';
import { EqualToDirective } from './equalTo.directive';
import { EmailValidatorDirective } from './emailValidator.directive';

@NgModule({
  declarations: [EmailValidatorDirective, EqualToDirective, ComplexDirective, DynamicFieldSharedDirective, PostcodeDirective, WholeDirective, ContainsDirective, VatnumberDirective],
  imports: [
    CommonModule
  ],
  exports: [EqualToDirective, EmailValidatorDirective, ComplexDirective, DynamicFieldSharedDirective, PostcodeDirective, WholeDirective, ContainsDirective, VatnumberDirective]
})
export class DirectivesModule {}
