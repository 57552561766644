<mat-nav-list>
  <div mat-list-item class="d-flex flex-row align-items-center mb-3">
    <mat-icon>fingerprint</mat-icon>
    <div class="d-flex flex-column flex-grow-1 mx-3">
      <span mat-line>{{'ID'| translate}}</span>
      <span mat-line>{{data.entity.id}}</span>
    </div>
    <button type="button" matSuffix mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.entity.id"
      (click)="commonService.copyHandler('ID')">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>

  <div mat-list-item class="d-flex flex-row align-items-center mb-3">
    <mat-icon>calendar_today</mat-icon>
    <div class="d-flex flex-column flex-grow-1 mx-3">
      <span mat-line>{{'Created Date'| translate}}</span>
      <span mat-line>{{data.entity.createdDate | amFromUtc | amLocal | amDateFormat:'DD/MM/YYYY HH:mm'}}</span>
    </div>
    <button type="button" matSuffix mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.entity.createdDate"
      (click)="commonService.copyHandler('Created Date')">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>

  <div mat-list-item class="d-flex flex-row align-items-center mb-3">
    <mat-icon>person</mat-icon>
    <div class="d-flex flex-column flex-grow-1 mx-3">
      <span mat-line>{{'Created By'| translate}}</span>
      <span mat-line>{{data.entity.createdBy}}</span>
    </div>
    <button type="button" matSuffix mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.entity.createdBy"
      (click)="commonService.copyHandler('Created By')">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>

  <div mat-list-item class="d-flex flex-row align-items-center mb-3">
    <mat-icon>calendar_today</mat-icon>
    <div class="d-flex flex-column flex-grow-1 mx-3">
      <span mat-line>{{'Updated Date'| translate}}</span>
      <span mat-line>{{data.entity.updatedDate | amFromUtc | amLocal | amDateFormat:'DD/MM/YYYY HH:mm'}}</span>
    </div>
    <button type="button" matSuffix mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.entity.updatedDate"
      (click)="commonService.copyHandler('Updated Date')">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>

  <div mat-list-item class="d-flex flex-row align-items-center mb-3">
    <mat-icon>person</mat-icon>
    <div class="d-flex flex-column flex-grow-1 mx-3">
      <span mat-line>{{'Updated By'| translate}}</span>
      <span mat-line>{{data.entity.updatedBy}}</span>
    </div>
    <button type="button" matSuffix mat-icon-button matTooltip="Copy" ngxClipboard [cbContent]="data.entity.updatedBy"
      (click)="commonService.copyHandler('Updated By')">
      <mat-icon>content_copy</mat-icon>
    </button>
  </div>
</mat-nav-list>
