<div class="preview-wrapper">
  <div class="d-flex align-items-center justify-content-between mb-2">
    <div class="text-grey">Preview</div>
  </div>

  <div class="preview-notification">
    <h4>iOS</h4>
    <div class="notification mb-3">
      <div class="d-flex align-items-center">
        <div class="icon-wrapper mr-2">
          <img
            [src]="sessionService.schemeImageUrl ? sessionService.schemeImageUrl : 'assets/preview/notification/loyale-icon.svg'"
            width="38" height="38" class="br-5">
        </div>
        <div class="text-wrapper flex-grow-1">
          <div class="d-flex flex-row justify-content-between align-items-start">
            <div class="title" [innerHtml]="pushNotification.title ? pushNotification.title : 'Title'">
            </div>
            <div class="time text-grey">15m ago</div>
          </div>
          <div class="text" [innerHtml]="pushNotification.subTitle ? pushNotification.subTitle : 'Message'"></div>
        </div>
      </div>
    </div>

    <h4>Android</h4>
    <div class="notification">
      <div class="d-flex align-items-center">
        <div class="icon-wrapper mr-2">
          <img
            [src]="sessionService.schemeImageUrl ? sessionService.schemeImageUrl : 'assets/preview/notification/loyale-icon.svg'"
            width="18" height="18" class="br-5">
        </div>
        <div class="text-grey">{{sessionService.schemeName}}</div>
        <div class="time text-grey">&nbsp;-&nbsp;15m</div>
      </div>
      <div class="title" [innerHtml]="pushNotification.title ? pushNotification.title : 'Title'"></div>
      <div class="text" [innerHtml]="pushNotification.subTitle? pushNotification.subTitle : 'Message'">
      </div>
    </div>
  </div>
</div>
