/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddressView } from './addressView';
import { CoordinateView } from './coordinateView';
import { LocationType } from './locationType';
import { WebStoreType } from './webStoreType';

export interface OutletInsertView { 
    franchiseId: string;
    coordinate?: CoordinateView;
    name: string;
    address?: AddressView;
    phoneNumber?: string;
    openingHours: Array<string>;
    imageUrl?: string;
    type?: LocationType;
    webstoreUrl?: string;
    contactEmail?: string;
    facebook?: string;
    instagram?: string;
    hidden?: boolean;
    isDefault?: boolean;
    webstoreType?: WebStoreType;
}