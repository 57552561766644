/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdminInvite } from './adminInvite';
import { AppType } from './appType';
import { CustomerLevel } from './customerLevel';
import { Franchise } from './franchise';
import { Level } from './level';
import { PaymentType } from './paymentType';
import { Rounding } from './rounding';
import { SchemeOrigin } from './schemeOrigin';
import { SchemeStatus } from './schemeStatus';
import { SchemeSubscription } from './schemeSubscription';
import { Transaction } from './transaction';

export interface Scheme { 
    id?: string;
    name?: string;
    currency?: string;
    description?: string;
    website?: string;
    color?: string;
    imageUrl?: string;
    pointRedemptionPerCurrency?: number;
    inLoyale?: boolean;
    connectionStringByte?: string;
    readerConnectionStringByte?: string;
    hidden?: boolean;
    rounding?: Rounding;
    setupCompleted?: boolean;
    franchises?: Array<Franchise>;
    transactions?: Array<Transaction>;
    adminInvites?: Array<AdminInvite>;
    status?: SchemeStatus;
    schemeSubscription?: SchemeSubscription;
    origin?: SchemeOrigin;
    customerLevels?: Array<CustomerLevel>;
    levels?: Array<Level>;
    requireTwoFactorAuth?: boolean;
    timeZone?: string;
    paymentType?: PaymentType;
    appType?: AppType;
    iosAppUrl?: string;
    androidAppUrl?: string;
    webAppUrl?: string;
    supportEmail?: string;
    infoEmail?: string;
    accountId?: number;
    createdDate?: Date;
    updatedDate?: Date;
}