/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BarcodeType } from './barcodeType';
import { CustomLinkResultView } from './customLinkResultView';

export interface WhiteLabelSettingResultView { 
    id?: string;
    color1?: string;
    color2?: string;
    schemeId?: string;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
    customLinks?: Array<CustomLinkResultView>;
    barcodeType?: BarcodeType;
}