import { Directive, Input } from '@angular/core';
import { ValidatorFn, FormControl, NG_VALIDATORS, AbstractControl } from '@angular/forms';

@Directive({
  selector: '[whole]',
  providers: [{ provide: NG_VALIDATORS, useExisting: WholeDirective, multi: true }]
})

export class WholeDirective {
  @Input('whole') whole;

  constructor() {
  }

  validate(c: AbstractControl) {
    let isValid = /^0*[1-9]\d*$/.test(c.value);

    const validationState = !isValid ? { whole: true } : null;
    return validationState;
  }
}