import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'loyale-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  @Input() category = null;
  @Input() item = null;
  @Input() message = null;
  @Input() disableTour = true;
  @Input() hideTour = true;
  icon = null;
  isExpanded = true;

  @Output() startTourEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    this.getIcon();
  }

  getIcon() {
    switch (this.category.toLowerCase()) {
      case 'dashboard': {
        this.icon = 'analytics';
        break;
      }
      case 'transactions': {
        this.icon = 'compare_arrows';
        break;
      }
      case 'reports': {
        this.icon = 'description';
        break;
      }
      case 'brands': {
        this.icon = 'storefront';
        break;
      }
      case 'customers': {
        this.icon = 'people';
        break;
      }
      case 'groups': {
        this.icon = 'folder';
        break;
      }
      case 'demographics': {
        this.icon = 'donut_large';
        break;
      }
      case 'tags': {
        this.icon = 'label';
        break;
      }
      case 'messages': {
        this.icon = 'question_answer';
        break;
      }
      case 'push notifications': {
        this.icon = 'notifications';
        break;
      }
      case 'emails': {
        this.icon = 'email';
        break;
      }
      case 'posts': {
        this.icon = 'sticky_note_2';
        break;
      }
      case 'coupons': {
        this.icon = 'local_play';
        break;
      }
      case 'gift cards': {
        this.icon = 'card_membership';
        break;
      }
      case 'referrals': {
        this.icon = 'group_add';
        break;
      }
      case 'promo codes': {
        this.icon = 'abc';
        break;
      }
      case 'welcome': {
        this.icon = 'waving_hand';
        break;
      }
      case 'lotteries': {
        this.icon = 'award_star';
        break;
      }
      case 'products': {
        this.icon = 'shopping_bag';
        break;
      }
      case 'automations': {
        this.icon = 'bolt';
        break;
      }
      case 'integrations': {
        this.icon = 'hub';
        break;
      }
      case 'webhooks': {
        this.icon = 'webhook';
        break;
      }
      case 'settings': {
        this.icon = 'settings';
        break;
      }
      case 'feedback forms': {
        this.icon = 'sms_failed';
        break;
      }
      case 'user': {
        this.icon = 'account_box';
        break;
      }
      case 'categories': {
        this.icon = 'category';
        break;
      }
      case 'tickets': {
        this.icon = 'award_star';
        break;
      }
      case 'prizes': {
        this.icon = 'award_star';
        break;
      }
      case 'outlets': {
        this.icon = 'store';
        break;
      }
      case 'punch cards': {
        this.icon = 'square';
        break;
      }
      case 'point of sale': {
        this.icon = 'point_of_sale';
        break;
      }
      case 'translate': {
        this.icon = 'translate';
        break;
      }
      case 'api keys': {
        this.icon = 'api';
        break;
      }
      case 'scheme': {
        this.icon = 'work';
        break;
      }
      case 'agents': {
        this.icon = 'support_agent';
        break;
      }
      case 'subscription': {
        this.icon = 'payment';
        break;
      }
      case 'email': {
        this.icon = 'email';
        break;
      }
      case 'policies': {
        this.icon = 'policy';
        break;
      }
      case 'verification': {
        this.icon = 'verified';
        break;
      }
      case 'reset': {
        this.icon = 'lock_reset';
        break;
      }
      case 'inactivity': {
        this.icon = 'speed';
        break;
      }
      case 'configuration': {
        this.icon = 'tune';
        break;
      }
      case 'sms': {
        this.icon = 'sms';
        break;
      }
      case 'setup guide': {
        this.icon = 'task_alt';
        break;
      }
      case 'points': {
        this.icon = 'auto_awesome';
        break;
      }
      case 'movements': {
        this.icon = 'move_up';
        break;
      }
      case 'media banners': {
        this.icon = 'screenshot_monitor';
        break;
      }
      case 'exports': {
        this.icon = 'download_for_offline';
        break;
      }
      default: {
        this.icon = 'menu';
        break;
      }
    }
  }

  startTourTrigger() {
    this.startTourEvent.emit(true);
  }
}
