/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ChangeCustomerPasswordView } from '../model/changeCustomerPasswordView';
import { ChangePasswordView } from '../model/changePasswordView';
import { CustomerForgotPasswordView } from '../model/customerForgotPasswordView';
import { ForgotPasswordView } from '../model/forgotPasswordView';
import { InviteFriendView } from '../model/inviteFriendView';
import { NewPasswordView } from '../model/newPasswordView';
import { ResetPasswordView } from '../model/resetPasswordView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AccountService {

    protected basePath = 'https://api.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Change password for existing admin using another admin.
     * 
     * @param scheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountChangeAdminPasswordPost(scheme: string, body?: ChangePasswordView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountChangeAdminPasswordPost(scheme: string, body?: ChangePasswordView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountChangeAdminPasswordPost(scheme: string, body?: ChangePasswordView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountChangeAdminPasswordPost(scheme: string, body?: ChangePasswordView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (scheme === null || scheme === undefined) {
            throw new Error('Required parameter scheme was null or undefined when calling apiAccountChangeAdminPasswordPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ChangeAdminPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change password for existing user
     * This endpoint changes the password of the customer whose Id is passed in the body request. It checks if the currentPassword is correct and if so changes the users password to the one passed in newPassword in the body request, newPassword must be at least 6 characters long and not longer than 25.
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountChangePasswordPost(body?: ChangePasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountChangePasswordPost(body?: ChangePasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountChangePasswordPost(body?: ChangePasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountChangePasswordPost(body?: ChangePasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ChangePassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param file 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountCustomResetPasswordPostForm(file?: Blob, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountCustomResetPasswordPostForm(file?: Blob, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountCustomResetPasswordPostForm(file?: Blob, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountCustomResetPasswordPostForm(file?: Blob, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/CustomResetPassword`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send reset password link to provided valid email
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountForgotPasswordAdminPost(body?: ForgotPasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountForgotPasswordAdminPost(body?: ForgotPasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountForgotPasswordAdminPost(body?: ForgotPasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountForgotPasswordAdminPost(body?: ForgotPasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ForgotPasswordAdmin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send reset password link to provided valid email
     * This endpoint is used to send a reset password link to the email provided in the body of the request.
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountForgotPasswordPost(body?: ForgotPasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountForgotPasswordPost(body?: ForgotPasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountForgotPasswordPost(body?: ForgotPasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountForgotPasswordPost(body?: ForgotPasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ForgotPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Send invitation to provided customer email.
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountInviteFriendPost(body?: InviteFriendView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountInviteFriendPost(body?: InviteFriendView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountInviteFriendPost(body?: InviteFriendView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountInviteFriendPost(body?: InviteFriendView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/InviteFriend`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change password for existing customer using admin account.
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountNewPasswordPost(body?: NewPasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountNewPasswordPost(body?: NewPasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountNewPasswordPost(body?: NewPasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountNewPasswordPost(body?: NewPasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/NewPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param profileId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountReferralUrlByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountReferralUrlByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountReferralUrlByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountReferralUrlByProfileIdProfileIdGet(profileId: string, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiAccountReferralUrlByProfileIdProfileIdGet.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Account/ReferralUrl/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountReferralUrlGet(scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountReferralUrlGet(scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountReferralUrlGet(scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountReferralUrlGet(scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/Account/ReferralUrl`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Reset admin password with a new password.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountResetPasswordAdminPost(body?: ResetPasswordView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountResetPasswordAdminPost(body?: ResetPasswordView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountResetPasswordAdminPost(body?: ResetPasswordView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountResetPasswordAdminPost(body?: ResetPasswordView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ResetPasswordAdmin`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update password
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiAccountResetPasswordPost(body?: ResetPasswordView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiAccountResetPasswordPost(body?: ResetPasswordView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiAccountResetPasswordPost(body?: ResetPasswordView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiAccountResetPasswordPost(body?: ResetPasswordView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Account/ResetPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Change password
     * This endpoint allows the customer to change their password
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2AccountChangePasswordPost(body?: ChangeCustomerPasswordView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2AccountChangePasswordPost(body?: ChangeCustomerPasswordView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2AccountChangePasswordPost(body?: ChangeCustomerPasswordView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2AccountChangePasswordPost(body?: ChangeCustomerPasswordView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v2/Account/ChangePassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Forgot Password
     * This endpoint allows the customer to request a reset password email.
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2AccountForgotPasswordPost(body?: CustomerForgotPasswordView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2AccountForgotPasswordPost(body?: CustomerForgotPasswordView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2AccountForgotPasswordPost(body?: CustomerForgotPasswordView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2AccountForgotPasswordPost(body?: CustomerForgotPasswordView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/v2/Account/ForgotPassword`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
