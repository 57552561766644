/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AdminInvite } from './adminInvite';

export interface AbstractIdentityUser { 
    firstName: string;
    lastName: string;
    lastConnectedDate?: Date;
    createdDate?: Date;
    updatedDate?: Date;
    signUpPlatform?: string;
    deleted?: boolean;
    adminInvites?: Array<AdminInvite>;
    externalRefId?: string;
    createdBy?: string;
    updatedBy?: string;
    recoveryCode?: string;
    lastPasswordChangeDate?: Date;
    marketingSub?: boolean;
    id?: string;
    userName?: string;
    normalizedUserName?: string;
    email?: string;
    normalizedEmail?: string;
    emailConfirmed?: boolean;
    passwordHash?: string;
    securityStamp?: string;
    concurrencyStamp?: string;
    phoneNumber?: string;
    phoneNumberConfirmed?: boolean;
    twoFactorEnabled?: boolean;
    lockoutEnd?: Date;
    lockoutEnabled?: boolean;
    accessFailedCount?: number;
}