import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PushNotificationService } from 'src/app/swagger';
import { NewPushNotificationDialogComponent } from '../action-selector-dialog/new-push-notification-dialog/new-push-notification-dialog.component';

@Component({
  selector: 'loyale-expiry-reminder',
  templateUrl: './expiry-reminder.component.html',
  styleUrls: ['./expiry-reminder.component.scss']
})
export class ExpiryReminderComponent implements OnInit {
  awaitingGet = false;
  pushNotifications = [];
  filteredPushNotifications = [];

  expiryReminderObject = {
    notify: false,
    notificationTime: '',
    daysBeforeExpire: 0,
    pushNotificationId: ''
  };

  expiryReminderForm: UntypedFormGroup = new UntypedFormGroup({
    notify: new UntypedFormControl(false, []),
    notificationTime: new UntypedFormControl('', [Validators.required]),
    daysBeforeExpire: new UntypedFormControl(null, [Validators.required]),
    pushNotificationId: new UntypedFormControl('', [Validators.required]),
  });

  @Input() notify: boolean;
  @Input() notificationTime: string;
  @Input() daysBeforeExpire: number;
  @Input() pushNotificationId: string;

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.expiryReminderForm.markAllAsTouched();
    }
  }

  @Output() onChange = new EventEmitter();
  @Output() formValidStatus = new EventEmitter();

  constructor(private pushNotificationsService: PushNotificationService, private dialog: MatDialog, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getPushNotifications();

    if (this.notify) {
      this.expiryReminderObject.notify = this.notify;
      this.expiryReminderObject.notificationTime = this.notificationTime;
      this.expiryReminderObject.daysBeforeExpire = this.daysBeforeExpire;
      this.expiryReminderObject.pushNotificationId = this.pushNotificationId;

      this.expiryReminderForm.patchValue({ notify: this.notify });
      this.expiryReminderForm.patchValue({ notificationTime: this.notificationTime });
      this.expiryReminderForm.patchValue({ daysBeforeExpire: this.daysBeforeExpire });
      this.expiryReminderForm.patchValue({ pushNotificationId: this.pushNotificationId });
    }

    this.expiryReminderForm.valueChanges.subscribe(change => {
      this.onChange.emit(this.expiryReminderForm.value);
      this.formValidStatus.emit(this.expiryReminderForm.valid);
    });
  }

  getPushNotifications() {
    this.awaitingGet = true;
    this.pushNotificationsService.apiPushNotificationGet(null, '-createdDate').subscribe(res => {
      this.awaitingGet = false;
      this.pushNotifications = res;
      this.filteredPushNotifications = this.pushNotifications;
    });
  }

  onNewPushNotification() {
    const dialogRef = this.dialog.open(NewPushNotificationDialogComponent, { width: '80vw' });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.getPushNotifications();
        this.expiryReminderForm.patchValue({
          pushNotificationId: res.id
        });
      }
    });
  }

  changeExpiryReminder() {
    console.log(this.expiryReminderObject.notify);
    this.expiryReminderForm.patchValue({ notify: !this.expiryReminderForm.get('notify').value });
    this.expiryReminderObject.notify = !this.expiryReminderObject.notify;
  }
}
