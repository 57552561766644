/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Rounding } from './rounding';
import { SchemeOrigin } from './schemeOrigin';
import { SchemeStatus } from './schemeStatus';
import { SubscriptionType } from './subscriptionType';

export interface SchemeWithDatabaseInsertView { 
    databaseName: string;
    status?: SchemeStatus;
    name: string;
    currency: string;
    description?: string;
    website?: string;
    imageUrl?: string;
    color?: string;
    inLoyale: boolean;
    pointRedemptionPerCurrency: number;
    hidden?: boolean;
    rounding?: Rounding;
    planId?: string;
    origin?: SchemeOrigin;
    subscriptionType?: SubscriptionType;
    timezone: string;
    supportEmail?: string;
    infoEmail?: string;
}