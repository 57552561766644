/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AppType } from './appType';
import { Rounding } from './rounding';
import { SchemeStatus } from './schemeStatus';

export interface SchemePutView { 
    id?: string;
    name?: string;
    currency?: string;
    description?: string;
    website?: string;
    color?: string;
    imageUrl?: string;
    pointRedemptionPerCurrency?: number;
    hidden?: boolean;
    rounding?: Rounding;
    status?: SchemeStatus;
    planId?: string;
    requireTwoFactorAuth?: boolean;
    timezone?: string;
    appType?: AppType;
    iosAppUrl?: string;
    androidAppUrl?: string;
    webAppUrl?: string;
    supportEmail?: string;
    infoEmail?: string;
}