import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NestedOutlets, SessionService } from '../../../services/session.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ActionConnectorInterface } from '../../stepper/actionConnector.interface';
import { ActionType } from '../../stepper/action.enum';
import { CouponInsertView, CouponService, FranchiseService } from '../../../swagger';
import { PromiseService } from '../../../services/promise.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../../services/common.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { MainActionInterface } from '../main-action.interface';
import { tap } from 'rxjs/operators';
import * as cloneDeep from 'lodash/cloneDeep';

@Component({
  selector: 'loyale-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit, MainActionInterface {

  awaitingPost = false;
  awaitingGet = false;
  allOutlets = false;

  infiniteCustomerUses = false;
  infiniteGlobalUses = false;

  coupon: any = null;
  couponCopy: any = null;

  couponForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required]),
    description: new UntypedFormControl('', [Validators.required]),
    discountType: new UntypedFormControl(0, [Validators.required]),
    initialNumberOfUses: new UntypedFormControl(null, [Validators.required]),
    from: new UntypedFormControl(null, []),
    to: new UntypedFormControl(null, []),
    imageUrl: new UntypedFormControl(null, [Validators.required]),
    isDynamic: new UntypedFormControl(false, [Validators.required]),
    outletId: new UntypedFormControl([], []),
    externalReference: new UntypedFormControl('', []),
    validFor: new UntypedFormControl(null, []),
    validAfter: new UntypedFormControl(null, []),
    maxUses: new UntypedFormControl(0, [Validators.required]),
    discountAmount: new UntypedFormControl(0, []),
    terms: new UntypedFormControl('', []),
    notify: new UntypedFormControl(false, []),
    notificationTime: new UntypedFormControl(null, []),
    daysBeforeExpire: new UntypedFormControl(null, []),
    pushNotificationId: new UntypedFormControl(null, [])
  });

  markNotifyTouched = false;

  nestedOutlets: NestedOutlets[] = [];
  outletsFilter = null;
  filteredOutlets: NestedOutlets[] = [];

  @Input() couponId = null;

  @Output() changeFormData: EventEmitter<any> = new EventEmitter();
  @Output() entityChanges: EventEmitter<boolean> = new EventEmitter(false);
  @Output() couponData: EventEmitter<any> = new EventEmitter(this.couponForm.value);

  public readonly dataUpdate: BehaviorSubject<ActionConnectorInterface> = new BehaviorSubject<ActionConnectorInterface>({
    actionType: ActionType.COUPON,
    actionData: this.couponForm.value as CouponInsertView,
    formValid: this.couponForm.valid
  });

  constructor(private promiseService: PromiseService, private translate: TranslateService, public router: Router, public sessionService: SessionService, private franchiseService: FranchiseService, private route: ActivatedRoute, public commonService: CommonService, private snackBar: MatSnackBar, private couponService: CouponService) {
  }

  ngOnInit() {
    if (this.couponId) {
      this.getCoupon();
    }

    this.promiseService.getNestedOutlets().then(res => {
      this.nestedOutlets = res;
      this.filteredOutlets = this.nestedOutlets;
    });

    this.couponForm.valueChanges.subscribe(changes => {

      this.dataUpdate.next(
        {
          actionType: ActionType.COUPON,
          actionData: this.couponForm.value,
          formValid: this.couponForm.valid
        }
      );

      if (this.couponForm.touched) {
        this.couponData.next(changes);
      }
      this.coupon = { ...this.coupon, ...this.couponForm.value };

      this.entityChanges.next(JSON.stringify(this.couponCopy) !== JSON.stringify(this.coupon));
    });
  }

  outletsSearchChanges() {
    const nestedOutletsCopy = cloneDeep(this.nestedOutlets);

    if (!nestedOutletsCopy) {
      return;
    }

    let filter = this.outletsFilter;

    if (filter === '') {
      this.filteredOutlets = JSON.parse(JSON.stringify(this.nestedOutlets));
      return;
    } else {
      filter = filter.toLowerCase();

      nestedOutletsCopy.forEach((outlet: any) => {
        outlet.outlets = outlet.outlets.filter(element => element.name.toLowerCase().indexOf(filter) > -1);
      });

      this.filteredOutlets = nestedOutletsCopy.filter(outlet => outlet.outlets.length !== 0);
    }
  }

  getCoupon() {
    this.awaitingGet = true;
    this.couponService.apiCouponIdGet(this.couponId).subscribe(res => {
      this.awaitingGet = false;

      this.coupon = res;
      this.couponCopy = res;
      this.couponForm.patchValue({ ...res });

      if (this.coupon.initialNumberOfUses == -1) {
        this.infiniteCustomerUses = true;
      }

      if (this.coupon.maxUses == -1) {
        this.infiniteGlobalUses = true;
      }

      if (this.coupon.outletId.length == 0) {
        this.allOutlets = true;
      }
    }, err => {
      this.awaitingGet = false;
      this.commonService.errorHandler(err);
    });
  }

  onInfiniteCustomerUsesChange() {
    if (this.infiniteCustomerUses) {

      this.couponForm.patchValue({ initialNumberOfUses: -1 });
    } else {
      this.couponForm.patchValue({ initialNumberOfUses: null });
    }
  }

  onInfiniteGlobalUsesChange() {
    if (this.infiniteGlobalUses) {
      this.couponForm.patchValue({ maxUses: -1 });
      this.couponForm.get('maxUses').setValidators([Validators.required]);
      this.couponForm.get('maxUses').updateValueAndValidity();
    } else {
      this.couponForm.get('maxUses').clearValidators();
      this.couponForm.get('maxUses').updateValueAndValidity();

      this.couponForm.patchValue({ maxUses: 0 });
    }
  }

  onDynamicExpiryChange() {
    if (this.couponForm.get('isDynamic').value) {
      this.couponForm.get('from').clearValidators();
      this.couponForm.get('from').updateValueAndValidity();

      this.couponForm.get('to').clearValidators();
      this.couponForm.get('to').updateValueAndValidity();

      this.couponForm.patchValue({ from: null });
      this.couponForm.patchValue({ to: null });

      this.couponForm.get('validFor').setValidators([Validators.required]);
      this.couponForm.get('validFor').updateValueAndValidity();

      this.couponForm.get('validAfter').updateValueAndValidity();
    } else {
      this.couponForm.get('validFor').clearValidators();
      this.couponForm.get('validFor').updateValueAndValidity();

      this.couponForm.get('validAfter').clearValidators();
      this.couponForm.get('validAfter').updateValueAndValidity();

      this.couponForm.patchValue({ validFor: null });
      this.couponForm.patchValue({ validAfter: null });

      this.couponForm.get('from').setValidators([Validators.required]);
      this.couponForm.get('from').updateValueAndValidity();

      this.couponForm.get('to').setValidators([Validators.required]);
      this.couponForm.get('to').updateValueAndValidity();
    }
  }

  onAllOutletsChange() {
    if (this.allOutlets) {
      this.couponForm.get('outletId').clearValidators();
      this.couponForm.get('outletId').updateValueAndValidity();
      this.couponForm.patchValue({ outletId: [] });

    } else {
      this.couponForm.get('outletId').setValidators([Validators.required]);
      this.couponForm.get('outletId').updateValueAndValidity();
    }
  }

  changeDiscountType() {
    if (this.couponForm.get('discountType').value === 0) {
      this.couponForm.get('discountAmount').clearValidators();
      this.couponForm.get('discountAmount').updateValueAndValidity();

    }
    if (this.couponForm.get('discountType').value === 1) {
      this.couponForm.get('discountAmount').setValidators([Validators.required]);
      this.couponForm.get('discountAmount').updateValueAndValidity();
    }

    if (this.couponForm.get('discountType').value === 2) {
      this.couponForm.get('discountAmount').setValidators([Validators.required, Validators.max(100), Validators.min(1)]);
      this.couponForm.get('discountAmount').updateValueAndValidity();
    }

  }

  setExpiryReminded(expiryRemindedData: any) {
    if (expiryRemindedData.notify) {
      this.couponForm.addControl('notify', new UntypedFormControl(expiryRemindedData.notify));
      this.couponForm.addControl('notificationTime', new UntypedFormControl(expiryRemindedData.notificationTime, [Validators.required]));
      this.couponForm.addControl('daysBeforeExpire', new UntypedFormControl(expiryRemindedData.daysBeforeExpire, [Validators.required]));
      this.couponForm.addControl('pushNotificationId', new UntypedFormControl(expiryRemindedData.pushNotificationId, [Validators.required]));

      this.couponForm.patchValue({ notify: expiryRemindedData.notify });
      this.couponForm.patchValue({ notificationTime: expiryRemindedData.notificationTime });
      this.couponForm.patchValue({ daysBeforeExpire: expiryRemindedData.daysBeforeExpire });
      this.couponForm.patchValue({ pushNotificationId: expiryRemindedData.pushNotificationId });
    } else {
      this.couponForm.removeControl('notify');
      this.couponForm.removeControl('notificationTime');
      this.couponForm.removeControl('daysBeforeExpire');
      this.couponForm.removeControl('pushNotificationId');
    }
    this.changeEvent(this.couponForm);
  }

  public getData(): ActionConnectorInterface {
    this.couponForm.markAllAsTouched();
    this.markNotifyTouched = true;

    return {
      actionType: ActionType.COUPON,
      actionData: this.couponForm.value as CouponInsertView,
      formValid: this.couponForm.valid
    };
  }

  dateRangeOnChange(event: any) {
    if (this.couponForm.get('from').value !== event[0] || this.couponForm.get('to').value !== event[1]) {
      this.couponForm.patchValue({ from: event[0] });
      this.couponForm.patchValue({ to: event[1] });
    }
  }

  changeEvent(form: any) {
    if (form.valid) {
      this.dataUpdate.next({
        actionType: ActionType.COUPON,
        actionData: this.couponForm.value as CouponInsertView,
        formValid: this.couponForm.valid
      });
    }
  }

  createNew(): Observable<any> {
    if (this.couponForm.valid) {
      this.awaitingPost = true;
      return this.couponService.apiCouponPost(this.sessionService.schemeId, this.coupon).pipe(tap(res => {
        this.awaitingPost = false;

        this.dataUpdate.next(
          {
            actionType: ActionType.COUPON,
            actionData: res,
            formValid: this.couponForm.valid
          }
        );

      }));
    } else {
      return undefined;
    }
  }

  update(): Observable<any> {
    if (this.couponForm.valid) {
      this.awaitingPost = true;
      return this.couponService.apiCouponPut(this.coupon).pipe(
        tap(res => {
          this.awaitingPost = false;

          this.dataUpdate.next(
            {
              actionType: ActionType.COUPON,
              actionData: res,
              formValid: this.couponForm.valid
            }
          );

          this.coupon = res;
          this.couponCopy = res;

          this.couponForm.patchValue({ ...res });

        })
      );
    } else {
      return null;
    }
  }
}
