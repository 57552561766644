/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LevelUpgradeCriteria } from './levelUpgradeCriteria';

export interface TierInsertView { 
    name: string;
    isDefault: boolean;
    expiryPeriod: number;
    upgradePeriod: number;
    guaranteedPeriod: number;
    levelsUpgradable?: boolean;
    hidden?: boolean;
    upgradeCriteria?: LevelUpgradeCriteria;
}