<div class="rule-option">
    <div class="row">
        <div class="col-12 col-lg-4">
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group">
                <mat-label>Property</mat-label>
                <mat-select [formControlName]="'group'+ field.group + field.name + 'property'" required>
                    <mat-option *ngFor="let property of field.properties" [value]="property.id"
                                (click)="compareType = property.compareType; selectedProperty = property">
                        {{property.name}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-lg-4">
            <!-- this is a dummy field, just for apperance -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == null">
                <mat-label>Condition</mat-label>
                <input matInput disabled>
            </mat-form-field>

            <!-- numbers operators -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 0">
                <mat-label>Condition</mat-label>
                <mat-select placeholder="Condition" [formControlName]="'group'+ field.group + field.name + 'operator'"
                            [required]="compareType == 0">
                    <mat-option *ngFor="let operator of numberOperators" [value]="operator.symbol">{{operator.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- text operators -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 1">
                <mat-label>Condition</mat-label>
                <mat-select placeholder="Condition" [formControlName]="'group'+ field.group + field.name + 'operator'"
                            [required]="compareType == 1">
                    <mat-option *ngFor="let operator of textOperators" [value]="operator.symbol">{{operator.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- list opertators -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 2">
                <mat-label>Condition</mat-label>
                <mat-select placeholder="Condition" [formControlName]="'group'+ field.group + field.name + 'operator'"
                            [required]="compareType == 2">
                    <mat-option *ngFor="let operator of listOperators" [value]="operator.symbol">{{operator.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- date opertators -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 3">
                <mat-label>Condition</mat-label>
                <mat-select placeholder="Condition" [formControlName]="'group'+ field.group + field.name + 'operator'"
                            [required]="compareType == 3">
                    <mat-option *ngFor="let operator of dateOperators" [value]="operator.symbol">{{operator.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- line items opertators -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 4">
                <mat-label>Condition</mat-label>
                <mat-select placeholder="Condition" [formControlName]="'group'+ field.group + field.name + 'operator'"
                            [required]="compareType == 4">
                    <mat-option *ngFor="let operator of lineItemOperators" [value]="operator.symbol">{{operator.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-12 col-lg-4">
            <!-- this is a dummy field, just for apperance -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == null">
                <mat-label>Value</mat-label>
                <input matInput disabled>
            </mat-form-field>

            <!-- number type -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 0">
                <mat-label>Value</mat-label>
                <input matInput [formControlName]="'group'+ field.group + field.name + 'value'" type="number"
                       [required]="compareType == 0">
            </mat-form-field>

            <!-- text type -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 1">
                <mat-label>Value</mat-label>
                <input matInput [formControlName]="'group'+ field.group + field.name + 'value'" type="text"
                       [required]="compareType == 1">
            </mat-form-field>

            <!-- list type -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 2">
                <mat-label>Value</mat-label>
                <mat-select [formControlName]="'group'+ field.group + field.name + 'value'" [required]="compareType == 2">
                    <mat-option *ngFor="let item of selectedProperty.listValues" [value]="item.value">{{item.name}}</mat-option>
                </mat-select>
            </mat-form-field>

            <!-- date type -->
            <mat-form-field appearance="outline" class="w-100" *ngIf="compareType == 3">
                <mat-label>Value</mat-label>
                <input matInput [owlDateTime]="picker" name="date" [(ngModel)]="dateMoment"
                       (ngModelChange)="onDateChange('group'+ field.group + field.name + 'value')" [required]="compareType == 3">
                <button type="button" mat-icon-button matSuffix [owlDateTimeTrigger]="picker">
                    <mat-icon>today</mat-icon>
                </button>
                <owl-date-time #picker pickerType="calendar"></owl-date-time>
            </mat-form-field>

            <!-- since the date picker is custom control, we have a hidden input bound to the form control value where we inject the formatted date -->
            <div [formGroup]="group" *ngIf="compareType == 3">
                <input hidden matInput [formControlName]="'group'+ field.group + field.name + 'value'">
            </div>

            <!-- text type -->
            <mat-form-field appearance="outline" class="w-100" [formGroup]="group" *ngIf="compareType == 4">
                <mat-label>Value</mat-label>
                <input matInput [formControlName]="'group'+ field.group + field.name + 'value'" type="text"
                       [required]="compareType == 4">
            </mat-form-field>
        </div>
    </div>
</div>
