/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FranchiseCategory } from './franchiseCategory';
import { Outlet } from './outlet';
import { Scheme } from './scheme';

export interface Franchise { 
    id?: string;
    name?: string;
    description?: string;
    schemeId?: string;
    scheme?: Scheme;
    imageUrl?: string;
    franchiseCategories?: Array<FranchiseCategory>;
    hidden?: boolean;
    outlets?: Array<Outlet>;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
}