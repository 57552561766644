export const environment = {
  production: false,
  name: "Staging",
  version: "1.3.154",
  projectName: "Loyale Backoffice",
  baseUrl: "https://app.staging.loyale.io",
  baseApiUrl: "https://api.staging.loyale.io",
  setupUrl: "https://setup.staging.loyale.io",
  intercomAppId: "npr0rw5i",
  trustIss: "jwt@loyalecoltd.com",
  trustSiteReference: "test_loyaleecom126793",
  trustPaymentAddition: "/api/Billing/TPDecodeParentJwt",
  trustBaseAmount: "1050",
  trustLiveStatus: 0,
  recaptchaSiteKey: "6LeeiMAZAAAAALinfToGaPRngg4ArPE3clxdAiPQ",
  recaptchaSecretKey: "6LeeiMAZAAAAAMP0W_o9oSQuWkXTStrLPhlFWaga",
  // Map API key use from the index.html directly
  googleMapsKey: "AIzaSyCXdQX77esdlHaZw18-CyTUXA_0YqzhqA8",
  subscribeUrl: "https://web.staging.loyale.io/join",
  storageEncryptKey: "x3muqzQV5JWjWUeLyHznbjheXdL8kiSsyhRJxDVy6yY6C72vUHwW9oCKVwQAPiGH",
  storageDecryptKey: "6C72vUHWUeLyHznbjheXXdL8kiSsy"
};
