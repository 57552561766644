/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CouponsLinkedAdminPutView } from '../model/couponsLinkedAdminPutView';
import { CouponsLinkedInsertView } from '../model/couponsLinkedInsertView';
import { CouponsLinkedPutView } from '../model/couponsLinkedPutView';
import { CouponsLinkedPutViewV2 } from '../model/couponsLinkedPutViewV2';
import { CouponsLinkedResultView } from '../model/couponsLinkedResultView';
import { ExportResultView } from '../model/exportResultView';
import { LinkedItemsCountResultView } from '../model/linkedItemsCountResultView';
import { UseCouponBatchView } from '../model/useCouponBatchView';
import { UseCouponView } from '../model/useCouponView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class CouponsLinkedService {

    protected basePath = 'https://api.staging.loyale.io';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves the Customer coupon
     * 
     * @param couponLinkedId 
     * @param profileId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedByProfileIdProfileIdCouponLinkedIdGet(couponLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiCouponsLinkedByProfileIdProfileIdCouponLinkedIdGet(couponLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiCouponsLinkedByProfileIdProfileIdCouponLinkedIdGet(couponLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiCouponsLinkedByProfileIdProfileIdCouponLinkedIdGet(couponLinkedId: string, profileId: string, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (couponLinkedId === null || couponLinkedId === undefined) {
            throw new Error('Required parameter couponLinkedId was null or undefined when calling apiCouponsLinkedByProfileIdProfileIdCouponLinkedIdGet.');
        }

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiCouponsLinkedByProfileIdProfileIdCouponLinkedIdGet.');
        }



        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CouponsLinkedResultView>('get',`${this.basePath}/api/CouponsLinked/byProfileId/${encodeURIComponent(String(profileId))}/${encodeURIComponent(String(couponLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves list of Customer coupons
     * 
     * @param profileId 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CouponsLinkedResultView>>;
    public apiCouponsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CouponsLinkedResultView>>>;
    public apiCouponsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CouponsLinkedResultView>>>;
    public apiCouponsLinkedByProfileIdProfileIdGet(profileId: string, filters?: string, sorts?: string, page?: number, pageSize?: number, xApiKey?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (profileId === null || profileId === undefined) {
            throw new Error('Required parameter profileId was null or undefined when calling apiCouponsLinkedByProfileIdProfileIdGet.');
        }







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CouponsLinkedResultView>>('get',`${this.basePath}/api/CouponsLinked/byProfileId/${encodeURIComponent(String(profileId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gets a count of Customer coupons
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param outletIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe?: 'body', reportProgress?: boolean): Observable<LinkedItemsCountResultView>;
    public apiCouponsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LinkedItemsCountResultView>>;
    public apiCouponsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LinkedItemsCountResultView>>;
    public apiCouponsLinkedCountGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (outletIds !== undefined && outletIds !== null) {
            queryParameters = queryParameters.set('OutletIds', <any>outletIds);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<LinkedItemsCountResultView>('get',`${this.basePath}/api/CouponsLinked/Count`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete the Customer coupon
     * 
     * @param couponLinkedId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedCouponLinkedIdDelete(couponLinkedId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiCouponsLinkedCouponLinkedIdDelete(couponLinkedId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiCouponsLinkedCouponLinkedIdDelete(couponLinkedId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiCouponsLinkedCouponLinkedIdDelete(couponLinkedId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (couponLinkedId === null || couponLinkedId === undefined) {
            throw new Error('Required parameter couponLinkedId was null or undefined when calling apiCouponsLinkedCouponLinkedIdDelete.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CouponsLinkedResultView>('delete',`${this.basePath}/api/CouponsLinked/${encodeURIComponent(String(couponLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Export customer coupons received by email.
     * 
     * @param body 
     * @param scheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param couponId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ExportResultView>>;
    public apiCouponsLinkedExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ExportResultView>>>;
    public apiCouponsLinkedExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ExportResultView>>>;
    public apiCouponsLinkedExportPost(body?: Array<string>, scheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (couponId !== undefined && couponId !== null) {
            queryParameters = queryParameters.set('couponId', <any>couponId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<ExportResultView>>('post',`${this.basePath}/api/CouponsLinked/Export`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Customer coupon
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedFavoritePut(body?: CouponsLinkedPutView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiCouponsLinkedFavoritePut(body?: CouponsLinkedPutView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiCouponsLinkedFavoritePut(body?: CouponsLinkedPutView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiCouponsLinkedFavoritePut(body?: CouponsLinkedPutView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('put',`${this.basePath}/api/CouponsLinked/Favorite`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves list of Customer coupons
     * 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param scheme 
     * @param outletIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, outletIds?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CouponsLinkedResultView>>;
    public apiCouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, outletIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CouponsLinkedResultView>>>;
    public apiCouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, outletIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CouponsLinkedResultView>>>;
    public apiCouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, scheme?: string, outletIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (outletIds !== undefined && outletIds !== null) {
            queryParameters = queryParameters.set('OutletIds', <any>outletIds);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CouponsLinkedResultView>>('get',`${this.basePath}/api/CouponsLinked`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Retrieves the Customer coupon
     * 
     * @param id 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedIdGet(id: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiCouponsLinkedIdGet(id: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiCouponsLinkedIdGet(id: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiCouponsLinkedIdGet(id: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling apiCouponsLinkedIdGet.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CouponsLinkedResultView>('get',`${this.basePath}/api/CouponsLinked/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Creates the Customer coupon
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedPost(body?: CouponsLinkedInsertView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiCouponsLinkedPost(body?: CouponsLinkedInsertView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiCouponsLinkedPost(body?: CouponsLinkedInsertView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiCouponsLinkedPost(body?: CouponsLinkedInsertView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('post',`${this.basePath}/api/CouponsLinked`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Customer coupon
     * 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedPut(body?: CouponsLinkedAdminPutView, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiCouponsLinkedPut(body?: CouponsLinkedAdminPutView, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiCouponsLinkedPut(body?: CouponsLinkedAdminPutView, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiCouponsLinkedPut(body?: CouponsLinkedAdminPutView, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('put',`${this.basePath}/api/CouponsLinked`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param couponLinkedId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedReplicateWebhookCouponLinkedIdPost(couponLinkedId: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCouponsLinkedReplicateWebhookCouponLinkedIdPost(couponLinkedId: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCouponsLinkedReplicateWebhookCouponLinkedIdPost(couponLinkedId: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCouponsLinkedReplicateWebhookCouponLinkedIdPost(couponLinkedId: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (couponLinkedId === null || couponLinkedId === undefined) {
            throw new Error('Required parameter couponLinkedId was null or undefined when calling apiCouponsLinkedReplicateWebhookCouponLinkedIdPost.');
        }


        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/CouponsLinked/ReplicateWebhook/${encodeURIComponent(String(couponLinkedId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the dates of the linked coupons when the parent coupon is updated
     * 
     * @param couponId 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedUpdatePost(couponId?: string, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCouponsLinkedUpdatePost(couponId?: string, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCouponsLinkedUpdatePost(couponId?: string, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCouponsLinkedUpdatePost(couponId?: string, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (couponId !== undefined && couponId !== null) {
            queryParameters = queryParameters.set('couponId', <any>couponId);
        }

        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/api/CouponsLinked/Update`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sends a report of used coupons for the given daterange and schemeId in csv format to the logged in user&#x27;s email
     * 
     * @param body 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param couponId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedUsageExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCouponsLinkedUsageExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCouponsLinkedUsageExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCouponsLinkedUsageExportPost(body?: Array<string>, xScheme?: string, filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (couponId !== undefined && couponId !== null) {
            queryParameters = queryParameters.set('couponId', <any>couponId);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/CouponsLinked/Usage/Export`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consumes the linked coupon with the given id
     * 
     * @param body 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiCouponsLinkedUseCouponPost(body?: UseCouponView, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiCouponsLinkedUseCouponPost(body?: UseCouponView, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiCouponsLinkedUseCouponPost(body?: UseCouponView, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiCouponsLinkedUseCouponPost(body?: UseCouponView, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/CouponsLinked/UseCoupon`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Coupon Linked by Barcode
     * This endpoint returns one coupon linked by the barcode.
     * @param linkedCouponBarCode 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCouponsLinkedByBarcodeLinkedCouponBarCodeGet(linkedCouponBarCode: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiPosCouponsLinkedByBarcodeLinkedCouponBarCodeGet(linkedCouponBarCode: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiPosCouponsLinkedByBarcodeLinkedCouponBarCodeGet(linkedCouponBarCode: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiPosCouponsLinkedByBarcodeLinkedCouponBarCodeGet(linkedCouponBarCode: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (linkedCouponBarCode === null || linkedCouponBarCode === undefined) {
            throw new Error('Required parameter linkedCouponBarCode was null or undefined when calling apiPosCouponsLinkedByBarcodeLinkedCouponBarCodeGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCouponsLinkedByBarcodeLinkedCouponBarCodeGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCouponsLinkedByBarcodeLinkedCouponBarCodeGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CouponsLinkedResultView>('get',`${this.basePath}/api/Pos/CouponsLinked/ByBarcode/${encodeURIComponent(String(linkedCouponBarCode))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Coupons Linked
     * This endpoint returns a list of coupons linked to the customer. These can be filtered, sorted and paged by the query parameters passed.
     * @param xApiKey 
     * @param xScheme 
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param outletIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCouponsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, outletIds?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CouponsLinkedResultView>>;
    public apiPosCouponsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, outletIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CouponsLinkedResultView>>>;
    public apiPosCouponsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, outletIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CouponsLinkedResultView>>>;
    public apiPosCouponsLinkedGet(xApiKey: string, xScheme: string, filters?: string, sorts?: string, page?: number, pageSize?: number, outletIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCouponsLinkedGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCouponsLinkedGet.');
        }






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (outletIds !== undefined && outletIds !== null) {
            queryParameters = queryParameters.set('OutletIds', <any>outletIds);
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CouponsLinkedResultView>>('get',`${this.basePath}/api/Pos/CouponsLinked`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Coupon Linked by ID
     * This endpoint returns one coupon linked by the id.
     * @param linkedCouponId 
     * @param xApiKey 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xApiKey: string, xScheme: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiPosCouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xApiKey: string, xScheme: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiPosCouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xApiKey: string, xScheme: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiPosCouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xApiKey: string, xScheme: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (linkedCouponId === null || linkedCouponId === undefined) {
            throw new Error('Required parameter linkedCouponId was null or undefined when calling apiPosCouponsLinkedLinkedCouponIdGet.');
        }

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCouponsLinkedLinkedCouponIdGet.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCouponsLinkedLinkedCouponIdGet.');
        }

        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CouponsLinkedResultView>('get',`${this.basePath}/api/Pos/CouponsLinked/${encodeURIComponent(String(linkedCouponId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Use Coupons
     * This endpoint allows you to send an array of coupon barcodes to get marked as used
     * @param xApiKey 
     * @param xScheme 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiPosCouponsLinkedUseCouponsPost(xApiKey: string, xScheme: string, body?: UseCouponBatchView, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiPosCouponsLinkedUseCouponsPost(xApiKey: string, xScheme: string, body?: UseCouponBatchView, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiPosCouponsLinkedUseCouponsPost(xApiKey: string, xScheme: string, body?: UseCouponBatchView, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiPosCouponsLinkedUseCouponsPost(xApiKey: string, xScheme: string, body?: UseCouponBatchView, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (xApiKey === null || xApiKey === undefined) {
            throw new Error('Required parameter xApiKey was null or undefined when calling apiPosCouponsLinkedUseCouponsPost.');
        }

        if (xScheme === null || xScheme === undefined) {
            throw new Error('Required parameter xScheme was null or undefined when calling apiPosCouponsLinkedUseCouponsPost.');
        }


        let headers = this.defaultHeaders;
        if (xApiKey !== undefined && xApiKey !== null) {
            headers = headers.set('X-ApiKey', String(xApiKey));
        }
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/api/Pos/CouponsLinked/UseCoupons`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Update the Customer coupon
     * 
     * @param linkedCouponId 
     * @param body 
     * @param scheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CouponsLinkedFavoriteLinkedCouponIdPut(linkedCouponId: string, body?: CouponsLinkedPutViewV2, scheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiV2CouponsLinkedFavoriteLinkedCouponIdPut(linkedCouponId: string, body?: CouponsLinkedPutViewV2, scheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiV2CouponsLinkedFavoriteLinkedCouponIdPut(linkedCouponId: string, body?: CouponsLinkedPutViewV2, scheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiV2CouponsLinkedFavoriteLinkedCouponIdPut(linkedCouponId: string, body?: CouponsLinkedPutViewV2, scheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (linkedCouponId === null || linkedCouponId === undefined) {
            throw new Error('Required parameter linkedCouponId was null or undefined when calling apiV2CouponsLinkedFavoriteLinkedCouponIdPut.');
        }



        let headers = this.defaultHeaders;
        if (scheme !== undefined && scheme !== null) {
            headers = headers.set('Scheme', String(scheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json-patch+json',
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<CouponsLinkedResultView>('put',`${this.basePath}/api/v2/CouponsLinked/Favorite/${encodeURIComponent(String(linkedCouponId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Coupons Linked
     * This endpoint returns a list of coupons linked to the customer. These can be filtered, sorted and paged by the query parameters passed.
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param xScheme 
     * @param outletIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<CouponsLinkedResultView>>;
    public apiV2CouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CouponsLinkedResultView>>>;
    public apiV2CouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CouponsLinkedResultView>>>;
    public apiV2CouponsLinkedGet(filters?: string, sorts?: string, page?: number, pageSize?: number, xScheme?: string, outletIds?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (outletIds !== undefined && outletIds !== null) {
            queryParameters = queryParameters.set('OutletIds', <any>outletIds);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<CouponsLinkedResultView>>('get',`${this.basePath}/api/v2/CouponsLinked`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Coupon Linked by ID
     * This endpoint returns one coupon linked by the id.
     * @param linkedCouponId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<CouponsLinkedResultView>;
    public apiV2CouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CouponsLinkedResultView>>;
    public apiV2CouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CouponsLinkedResultView>>;
    public apiV2CouponsLinkedLinkedCouponIdGet(linkedCouponId: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (linkedCouponId === null || linkedCouponId === undefined) {
            throw new Error('Required parameter linkedCouponId was null or undefined when calling apiV2CouponsLinkedLinkedCouponIdGet.');
        }


        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<CouponsLinkedResultView>('get',`${this.basePath}/api/v2/CouponsLinked/${encodeURIComponent(String(linkedCouponId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Get Coupon Usage
     * This endpoint returns a list of coupons linked that were used by the customer. These can be filtered, sorted and paged by the query parameters passed.
     * @param filters 
     * @param sorts 
     * @param page 
     * @param pageSize 
     * @param couponId 
     * @param xScheme 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public apiV2CouponsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, xScheme?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public apiV2CouponsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, xScheme?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public apiV2CouponsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, xScheme?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public apiV2CouponsLinkedUsageGet(filters?: string, sorts?: string, page?: number, pageSize?: number, couponId?: string, xScheme?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (filters !== undefined && filters !== null) {
            queryParameters = queryParameters.set('Filters', <any>filters);
        }
        if (sorts !== undefined && sorts !== null) {
            queryParameters = queryParameters.set('Sorts', <any>sorts);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('Page', <any>page);
        }
        if (pageSize !== undefined && pageSize !== null) {
            queryParameters = queryParameters.set('PageSize', <any>pageSize);
        }
        if (couponId !== undefined && couponId !== null) {
            queryParameters = queryParameters.set('couponId', <any>couponId);
        }

        let headers = this.defaultHeaders;
        if (xScheme !== undefined && xScheme !== null) {
            headers = headers.set('X-Scheme', String(xScheme));
        }

        // authentication (Bearer) required
        if (this.configuration.apiKeys && this.configuration.apiKeys["Authorization"]) {
            headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('get',`${this.basePath}/api/v2/CouponsLinked/Usage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
