/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AppType } from './appType';
import { FranchiseResultView } from './franchiseResultView';
import { PaymentType } from './paymentType';
import { Plan } from './plan';
import { Rounding } from './rounding';
import { SchemeFeaturesResultView } from './schemeFeaturesResultView';
import { SchemeOrigin } from './schemeOrigin';
import { SchemePolicyResultView } from './schemePolicyResultView';
import { SchemeStatus } from './schemeStatus';

export interface SchemeResultView { 
    id?: string;
    name?: string;
    currency?: string;
    description?: string;
    website?: string;
    color?: string;
    imageUrl?: string;
    pointRedemptionPerCurrency?: number;
    updatedDate?: Date;
    createdDate?: Date;
    inLoyale?: boolean;
    hidden?: boolean;
    franchises?: Array<FranchiseResultView>;
    rounding?: Rounding;
    status?: SchemeStatus;
    plan?: Plan;
    planId?: string;
    setupCompleted?: boolean;
    requireTwoFactorAuth?: boolean;
    timezone?: string;
    origin?: SchemeOrigin;
    paymentType?: PaymentType;
    latestPolicy?: SchemePolicyResultView;
    features?: SchemeFeaturesResultView;
    appType?: AppType;
    iosAppUrl?: string;
    androidAppUrl?: string;
    webAppUrl?: string;
    supportEmail?: string;
    infoEmail?: string;
}