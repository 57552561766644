import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EventTriggerInsertView, EventTriggerService, TriggerBlueprintResultView, TriggerBlueprintService } from '../../swagger';
import { DynamicFormComponent } from '../dynamic-form/dynamic-form.component';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CommonService } from '../../services/common.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

class History {
  group: number;
  rule: number;
}

class Rule {
  id: string;
  op: string;
  value: any;
}

@Component({
  selector: 'loyale-shared-trigger-event',
  templateUrl: './trigger-event.component.html',
  styleUrls: ['./trigger-event.component.scss']
})
export class TriggerEventComponent implements OnInit {
  @Input() new = false;

  @Input()
  public set markAsTouched(touched: boolean) {
    if (touched === true) {
      this.dynamicForm.formGroup.markAllAsTouched();
      this.eventForm.markAllAsTouched();
    }
  }

  @Output() outputTriggerId = new EventEmitter<any>();

  awaitingPost = false;
  trigger: EventTriggerInsertView = <EventTriggerInsertView> {};

  events: TriggerBlueprintResultView[] = [];

  ruleProperties = [];
  ruleCounter = 0;
  groupCounter = 0;
  history: History[] = [];

  ruleParameters = [];
  ruleErrors = [];

  @ViewChild('dynamicForm') dynamicForm: DynamicFormComponent;
  eventForm: UntypedFormGroup = new UntypedFormGroup({
    event: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(private translate: TranslateService, private triggerBlueprintService: TriggerBlueprintService, private snackBar: MatSnackBar, public commonService: CommonService, private evenTriggerService: EventTriggerService) { }

  ngOnInit() {
    if (this.new) {

    }

    this.triggerBlueprintService.apiTriggerBlueprintGet().subscribe(res => {
      this.events = res;
    });
  }

  onEventChange(event) {
    this.resetRules();
    this.trigger.triggerBlueprintName = event.id;

    if (event.blueprintProperties.length > 0) {
      this.ruleProperties = event.blueprintProperties;
      this.addAndRule();
    }
  }

  addAndRule() {
    this.dynamicForm.createSingleControl({
      'type': 'and',
      'name': 'rule' + this.ruleCounter,
      'group': this.groupCounter,
      'properties': this.ruleProperties
    });
    this.history.push({ group: this.groupCounter, rule: this.ruleCounter });
    this.ruleCounter++;
  }

  addOrRule() {
    this.groupCounter++;
    this.ruleCounter = 0;
    this.dynamicForm.createSingleControl({ 'type': 'or' });
    this.addAndRule();
  }

  resetRules() {
    this.ruleProperties = [];
    this.ruleCounter = 0;
    this.groupCounter = 0;
    this.history = [];
    this.dynamicForm.fields = [];
    this.dynamicForm.formGroup.reset();
  }

  generateRules() {
    const rawRules = this.dynamicForm.value;
    this.ruleParameters = [];
    this.ruleErrors = [];

    for (let h of this.history) {
      let rule: Rule = <Rule> {};
      rule.id = rawRules['group' + h.group + 'rule' + h.rule + 'property'];
      rule.op = rawRules['group' + h.group + 'rule' + h.rule + 'operator'];
      rule.value = rawRules['group' + h.group + 'rule' + h.rule + 'value'];

      if (!rule.id || !rule.op || !rule.value) {
        this.ruleErrors.push('Group ' + h.group + ' Rule ' + h.rule);
      } else {
        if (this.ruleParameters[h.group] == undefined) {
          this.ruleParameters[h.group] = [rule];
        } else {
          this.ruleParameters[h.group].push(rule);
        }
      }
    }

    this.trigger.triggerParameters = this.ruleParameters;
  }

  onSubmit(form: any) {
    this.generateRules();

    if (form.valid && this.ruleErrors.length == 0) {
      this.awaitingPost = true;
      this.evenTriggerService.apiEventTriggerPost(this.trigger).subscribe(res => {
        this.outputTriggerId.emit(res.id);
      }, err => {
        this.commonService.errorHandler(err);
        this.awaitingPost = false;
      });
    } else {
      this.snackBar.open('Invalid rule set');
      console.error(this.ruleErrors);
    }
  }

}
