/**
 * Loyale Core API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AbstractIdentityUser } from './abstractIdentityUser';
import { Admin } from './admin';
import { Role } from './role';

export interface AdminInvite { 
    id?: string;
    invitedAdminEmail?: string;
    senderAdminId?: string;
    senderAdmin?: AbstractIdentityUser;
    isUsed?: boolean;
    acceptedDate?: Date;
    expiryDate?: Date;
    schemeId?: string;
    invitationCode?: string;
    invitedRoleId?: string;
    invitedRole?: Role;
    invitedAdmin?: Admin;
    invitedAdminId?: string;
    createdBy?: string;
    updatedBy?: string;
    createdDate?: Date;
    updatedDate?: Date;
}