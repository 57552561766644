<mat-card class="mini-entity">
  <mat-card-header>
    <mat-card-title>{{outlet.name}}</mat-card-title>
    <mat-card-subtitle>{{outlet?.contactEmail}}</mat-card-subtitle>

    <div class="top-right-action">
      <a mat-icon-button matTooltip="Open" [routerLink]="['/outlets/details', outlet.id]" *ngIf="!disabled">
        <mat-icon>launch</mat-icon>
      </a>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div class="d-flex align-items-center mb-2" *ngIf="outlet.type == 0">
      <mat-icon class="mr-1">storefront</mat-icon>
      <div>
        <span>{{outlet.address.addressLine1}},&nbsp;</span>
        <span *ngIf="outlet.address.addressLine2 != ''">{{outlet.address.addressLine2}},&nbsp;</span>
        <span>{{outlet.address.town}}</span>
      </div>
    </div>
    <div class="d-flex align-items-center mb-2" *ngIf="outlet.type == 1">
      <mat-icon class="mr-1">language</mat-icon>
      <span>{{outlet.webstoreUrl}}</span>
    </div>
    <div class="d-flex align-items-center mb-2" *ngIf="outlet.phoneNumber">
      <mat-icon class="mr-1">call</mat-icon>
      <span>{{outlet.phoneNumber}}</span>
    </div>
  </mat-card-content>
</mat-card>
