<h1 mat-dialog-title>Choose a plan</h1>
<div mat-dialog-content>
  <loyale-spinner *ngIf="awaitingGet"></loyale-spinner>

  <ng-container *ngIf="!awaitingGet">

    <div class="free-trial d-flex align-items-center mb-4">
      <mat-icon>credit_card_off</mat-icon>
      <span class="ml-3">
        <strong>{{'30 Day Free Trial' | translate}}</strong>
      </span>
      <span class="mx-3">|</span>
      <span>{{'No credit card required, 30 day money-back guarantee' | translate}}</span>
    </div>

    <div class="plans">
      <table class="w-100 mb-4">
        <thead>
          <tr>
            <td class="text-left">
            </td>
            <td *ngFor="let plan of plans">
              <div class="text-center">
                <h5>{{plan.name}}</h5>
                <div class="text-accent">
                  <ng-container *ngIf="plan.monthlyBasePrice == 0">
                    Free
                  </ng-container>
                  <ng-container *ngIf="plan.monthlyBasePrice == -1">
                    Contact Us
                  </ng-container>
                  <ng-container *ngIf="plan.monthlyBasePrice > 0">
                    <span *ngIf="useUsd">
                      {{plan.monthlyBasePriceUsd | currency:'USD':'symbol':'1.0'}} / month
                    </span>
                    <span *ngIf="!useUsd">
                      {{plan.monthlyBasePrice | currency:'EUR':'symbol':'1.0'}} / month
                    </span>
                  </ng-container>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Agents</td>
            <td *ngFor="let plan of plans">
              {{plan.agentsLimit == -1 ? 'Unlimited' : plan.agentsLimit}}
            </td>
          </tr>
          <tr>
            <td>Integrations</td>
            <td *ngFor="let plan of plans">
              {{plan.integrationsLimit == -1 ? 'Unlimited' : plan.integrationsLimit}}
            </td>
          </tr>
          <tr>
            <td>Loyalty Wallet</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.points; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>POS App</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.points; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>API</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.api; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Customers&nbsp;<i>*</i></td>
            <td *ngFor="let plan of plans">
              {{plan.customersLimit}}&nbsp;{{'included' | translate}}
            </td>
          </tr>
          <tr>
            <td>Push Notifications&nbsp;<i>*</i></td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.pushNotifications; else noFeature">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <mat-icon color="accent">check_circle</mat-icon>
                  {{plan.pushNotificationsLimit}}&nbsp;{{'included' | translate}}
                </div>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Emails&nbsp;<i>*</i></td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.emails; else noFeature">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <mat-icon color="accent">check_circle</mat-icon>
                  {{plan.emailsLimit}}&nbsp;{{'included' | translate}}
                </div>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Points Scheme</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.points; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Automations</td>
            <td *ngFor="let plan of plans">
              <mat-icon color="accent">check_circle</mat-icon>
            </td>
          </tr>
          <tr>
            <td>Segments</td>
            <td *ngFor="let plan of plans">
              <mat-icon color="accent">check_circle</mat-icon>
            </td>
          </tr>
          <tr>
            <td>Posts</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.posts; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Referrals</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.referrals; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Coupons</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.coupons; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>SMS&nbsp;<i>*</i></td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.sms; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Points Expiry</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.pointsExpiry; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Multiple Groups and Levels</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.multipleGroups; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Multiple Brands and Outlets</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.multipleBrands; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Gift Cards</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.giftCards; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Lotteries</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.lotteries; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Feedback Forms</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.feedbackForms; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Products</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.products; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Messages</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.messages; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
          <tr>
            <td>Promo Codes</td>
            <td *ngFor="let plan of plans">
              <ng-container *ngIf="plan.promoCodes; else noFeature">
                <mat-icon color="accent">check_circle</mat-icon>
              </ng-container>
              <ng-template #noFeature>
                <span class="off"></span>
              </ng-template>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="6" class="text-left"><i>*&nbsp;{{'Additional charges apply over and above the inculded limit' |
                translate}}</i></td>
          </tr>
        </tfoot>
      </table>

      <div class="button-wrapper" *ngIf="sessionService.agentIsOwner">
        <table class="w-100">
          <tfoot>
            <tr>
              <td> </td>
              <ng-container>
                <td *ngFor="let plan of plans">
                  <div class="text-center mt-3">
                    <ng-container *ngIf="plan.name == 'Free'">
                      <button mat-flat-button type="button" color="accent" (click)="selectedPlanId = plan.id"
                        [disabled]="true">
                        <mat-icon *ngIf="selectedPlanId == plan.id" class="mr-1">check_circle</mat-icon>
                        <span *ngIf="sessionService.schemePlan.id != plan.id">Start</span>
                        <span *ngIf="sessionService.schemePlan.id == plan.id">Current Plan</span>
                      </button>
                    </ng-container>
                    <ng-container *ngIf="plan.name != 'Free'">
                      <button mat-flat-button type="button" color="accent" (click)="selectedPlanId = plan.id"
                        [disabled]="awaitingGet || plan.id == sessionService.schemePlan.id">
                        <mat-icon *ngIf="selectedPlanId == plan.id" class="mr-1">check_circle</mat-icon>
                        <span *ngIf="sessionService.schemePlan.id != plan.id">Start for Free</span>
                        <span *ngIf="sessionService.schemePlan.id == plan.id">Current Plan</span>
                      </button>
                    </ng-container>
                  </div>
                </td>
              </ng-container>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </ng-container>
</div>
<div mat-dialog-actions *ngIf="!sessionService.agentIsOwner">
  <div class="free-trial d-flex align-items-center mb-4">
    <mat-icon>credit_card_gear</mat-icon>
    <span class="mx-3">|</span>
    <span>{{'Only the scheme owner can change the subscription plan' | translate}}</span>
  </div>
</div>

<div mat-dialog-actions class="do-not-print" *ngIf="sessionService.agentIsOwner">
  <div class="flex-fill"></div>
  <button mat-flat-button [mat-dialog-close]>Close</button>
  <button mat-flat-button color='primary' (click)="onSubmit()"
    [disabled]="selectedPlanId == sessionService.schemePlan.id || awaitingGet || awaitingPost">Confirm
  </button>
</div>
