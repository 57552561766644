import { Injectable } from '@angular/core';
import { SessionService } from './session.service';

@Injectable({
  providedIn: 'root'
})
export class GuideService {

  constructor(public sessionService: SessionService) { }

  public set guideShpoify(show: boolean) {
    const key = 'guideShopify_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideShpoify() {
    const key = 'guideShopify_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideLightspeedL(show: boolean) {
    const key = 'guideLightspeedL_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideLightspeedL() {
    const key = 'guideLightspeedL_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideLightspeedR(show: boolean) {
    const key = 'guideLightspeedR_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideLightspeedR() {
    const key = 'guideLightspeedR_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideLightspeedX(show: boolean) {
    const key = 'guideLightspeedX_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideLightspeedX() {
    const key = 'guideLightspeedX_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideLightspeedK(show: boolean) {
    const key = 'guideLightspeedK_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideLightspeedK() {
    const key = 'guideLightspeedK_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideDemo(show: boolean) {
    const key = 'guideDemo_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideDemo() {
    const key = 'guideDemo_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideTour(show: boolean) {
    const key = 'guideTour_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideTour() {
    const key = 'guideTour_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guidePosts(show: boolean) {
    const key = 'guidePosts_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guidePosts() {
    const key = 'guidePosts_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guideEmails(show: boolean) {
    const key = 'guideEmails_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guideEmails() {
    const key = 'guideEmails_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }

  public set guidePushNotifications(show: boolean) {
    const key = 'guidePushNotifications_' + this.sessionService.schemeId
    localStorage.setItem(key, JSON.stringify(show));
  }

  public get guidePushNotifications() {
    const key = 'guidePushNotifications_' + this.sessionService.schemeId
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : true;
  }
}
